import React from 'react'
import Personnel from '../Personnel'
import stairsPic from 'img/stairs.webp'
import styles from './About.module.css'

function About() {
    return (
        <div className={'lowerPage ' + styles.About}>
            <div className={styles.aboutSection}>
                <img src={stairsPic} id={styles.stairsPic} alt='a'/>
                <div className={styles.aboutTextContainer}>
                    <h2>Om <br/> Ljusportalen</h2>
                    <span className='bar'></span>
                    <p className={styles.aboutText}>
                      Vi finns här för dig i livets alla skeden. Hos oss kan du 
                      boka samtal via video eller telefon med våra fantastiska 
                      medium och terapeuter, alltid till ett fast pris. Vi hjälper 
                      dig att finna klarhet, kraft och glädje.
                    </p>
                </div>
            </div>
            <Personnel />
           
        </div>
    )
}

export default About

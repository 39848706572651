import CompanyLogo from 'img/CompanyLogo'
import { useEffect, useState } from 'react'
import styles from './UnderConstructionPage.module.scss'

const targetDate = new Date('2022-04-15T12:30:00+0100')
const minutesPerDay = 60*24

const timeDiffs = (nowDate) => {
    let delta = (targetDate.getTime() - nowDate.getTime())/1000/60
    const days = Math.floor(delta/minutesPerDay)
    delta -= days * minutesPerDay

    const hours =  Math.floor(delta/60)
    delta -= hours * 60

    const minutes = Math.floor(delta)
    delta -= minutes
    return { days, hours, minutes }
}


function UnderConstructionPage({ onSecret }) {
    const [nowDate, setNowDate] = useState(new Date())

    const {days, hours, minutes} = timeDiffs(nowDate)
    
    useEffect(() => {
        setTimeout(() => {
            setNowDate(new Date())
        }, 1000)
    })
    return (
        <div className={styles.container}>
            <div style={{width:'80%', maxWidth: '1000px'}}>
                <CompanyLogo />
                <div className={styles.text}>
                    <p>Sidan <span onClick={onSecret}>är</span> parkerad</p>
                    {/* <p className={styles.time}>{days} dagar {hours} timmar <span onClick={onSecret}>{minutes}</span> minuter </p> */}
                </div>

            </div>
        </div>
    )
}

export default UnderConstructionPage
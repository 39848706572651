import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Footer.module.scss'
import stonePic from 'img/stones.webp' 
import aboutUsContent from 'db/aboutUsContent.json'
import howToBookContent from 'db/howToBookContent.json'
import priceContent from 'db/priceContent.js'
import PopupTriggerText from '../PopupTriggerText/'

function Footer() {

    return (
        <div className={styles.Footer} style={{backgroundImage: `url(${stonePic})`}}>
            <div className={styles.aboutSection}>
                <h3>Ljusportalen</h3>
                <p>En plats för att stilla sin nyfikenhet kring framtiden, spådomskonst och andra esoteriska ämnen.</p>
            </div>
            <div>
                <h3>Boka</h3>
                <div className={styles.link}><PopupTriggerText popupContent={howToBookContent}>Så går det till</PopupTriggerText></div>
                <div className={styles.link}><PopupTriggerText popupContent={priceContent}>Priser</PopupTriggerText></div>
            </div>
            <div>
                <h3>Kontakt</h3>
                <div className={styles.link}><a href='https://www.facebook.com/ljusportalen'>Facebook</a></div>
                <div className={styles.link}><a href='https://www.instagram.com/ljusportalen.se/'>Instagram</a></div>
                <div className={styles.link}><a href='mailto:support@ljusportalen.se'>Support</a></div>
            </div>
            <div>
                <h3>Företaget</h3>
                <div className={styles.link}><PopupTriggerText popupContent={aboutUsContent}>Om oss</PopupTriggerText></div>
                <div className={styles.link}><Link to='/villkor/allmana'>Allmäna Villkor</Link></div>
                <div className={styles.link}><Link to='/villkor/integritet'>Säkerhet och Integritet</Link></div>
                <div className={styles.link}><Link to='/bibliotek'>Bibliotek</Link></div>
                <div className={styles.link}><Link to='/blogg'>Blogg</Link></div>
            </div>
        </div>
    )
}

export default Footer
import React, { Children, useContext, useRef } from 'react'
import Header from '../Header'
import useMouseFollow from 'hooks/useMouseFollow'
import styles from './UpperPage.module.scss'
import MouseContext from 'context/MouseContext.js'
import NavBar from 'components/NavBar'


function UpperPage({ title, bgImgUrl, whiteText, midTitle, visibilityHelp, children }) {
  const cursorFollower = useRef()
  const lastMousePos = useContext(MouseContext)
  const mouseMove = useMouseFollow(cursorFollower, lastMousePos)

  return (<>
    <div
      className={styles.UpperPage + ` ${whiteText ? styles.whiteText : ''}`}
      style={{ backgroundImage: `url(${bgImgUrl})` }}
      onMouseMove={mouseMove}
      >
      <div style={{ justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
        <NavBar className={styles.centerNav} />
        {/* <Header midTitle={midTitle} whiteText={whiteText} visibilityHelp={visibilityHelp} /> */}
        {
          typeof title === 'string' ?
          <h1 className={styles.mainTitle}>{title}</h1> : title
        }
      </div>
      {
        children && <div className={styles.childBox}>
          {children}
        </div>
      }
      <div ref={cursorFollower} className='cursorFollow'></div>
    </div>
    </>
  )
}

export default UpperPage

import { convertToRaw } from "draft-js"
import draftjsToHtml from 'draftjs-to-html'
import { DateTime, Interval } from "luxon"
import { alert } from "react-custom-alert"

export const toggleInArray = (array, elementToToggle) => {
    const newArray = [...array]
    if(array.includes(elementToToggle)) {
        newArray = newArray.filter(element => element === elementToToggle)
    }
    return newArray
}
export const toHtmlString = (editorState) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent())
    
    //Ugly fix because library doesnt set text align for centered images
    for( const [key, entity] of Object.entries(rawContentState.entityMap)) { 
        console.log('setting!', entity);
        if(entity.type === 'IMAGE' && !entity.data.alignment) {
            entity.data.alignment = 'center'
        }
    }

    return draftjsToHtml(rawContentState)
}
export const userFeedback = (success, successMsg, errorMsg) => {
    if(success) {
        successMsg && alert({ type: 'success', message: successMsg})
    } else {
        alert({ type: 'error', message: errorMsg})
    }
}
export const sortByDate = (lateFirst) => { //Latefisrt if dates ahead should occupy low index
  return (a, b) => {
    const date1 = new Date(a.begins)
    const date2 = new Date(b.begins)
    if(lateFirst) return date2.getTime() - date1.getTime()
    return date1.getTime() - date2.getTime()
  }
}
export const representPhoneNumber = (phoneNumber) => {
  if(typeof phoneNumber !== 'string') return
  return phoneNumber.replace('+46', '0')
}
export const validatePhone = (string) => {
  return string.match(/^\+\d{2}\d{9}$/g) //Simple phone validation. Only intended for Swedish numbers
}
export const capitalizeString = (string) => {
  return `${string.charAt(0).toUpperCase()}${string.substring(1)}`
}
export const representDate = (date) => {
  if(typeof date === "string") {
    date = DateTime.fromISO(date)
  }
  return `${date.weekdayShort} ${date.day} ${date.monthShort} ${date.year}`
}
export const representInterval = (start, end) => {
  let interval
  if(typeof start === "string") {
    interval = Interval.fromISO(`${start}/${end}`)
  } else {
    interval = Interval.fromDateTimes(start, end)
  }
  return interval.toFormat('HH:mm')
}
export const getClientName = (booking) => {
  if(booking.bookedBy) {
    return `${booking.bookedBy.firstName} ${booking.bookedBy.lastName}`
  } else {
    return booking.email
  }
}
// Pick n random indices between 0 and max (length of array)
export const pickRandomInRange = (n, max) => {
  if(n > max) throw 'Cannot pick more than in range'
  // Will be very inefficent for large diff between min and max paired with large n
  const picks = []
  let pick
  for(let i = 0; i < n; i++) {
    pick = Math.floor(Math.random() * max)
    if(picks.includes(pick)) {
      i--
      continue 
    }
    picks.push(pick)
  }
  return picks
}
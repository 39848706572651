import React, {useRef} from 'react'
import styles from './WelcomeOverlay.module.scss'
import { CSSTransition } from 'react-transition-group'
import CompanyLogoV2 from 'img/CompanyLogo'

function WelcomeOverlay({ onClick, in: inProp}) {
    const overlay = useRef()
    
    return (
        <CSSTransition
            nodeRef={overlay}
            in={inProp}
            classNames={{
                appear: styles.appear,
                appearActive: styles.enterActive,
                exit: styles.exit,
                exitActive: styles.exitActive,
                exitDone: styles.exitDone
            }}
            appear
            unmountOnExit
            timeout={{appear: 2000, enter: 0, exit: 1000}}
        >
            <div className={styles.WelcomeOverlay} onClick={onClick} ref={overlay}>
                <CompanyLogoV2 textClass={styles.mainTitle}/>    
            </div>
        </CSSTransition>
    )
}

export default WelcomeOverlay

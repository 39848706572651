import Overlay from 'components/Overlay'
import React from 'react'
import styles from './AboutOverlay.module.scss'

function AboutOverlay({ title, text, element, onOutsideClick, imgUrl, lastChild, in: inProp, overflow }) {
    
    return (
        <Overlay in={inProp} onOutsideClick={onOutsideClick} overflow={overflow}>
            <div className={styles.overlayContent + ' unclickable'}>
                {imgUrl && <img src={imgUrl} className={styles.bigPic} alt='About'/>}
                {(text && title) ? <div className={styles.textContent}>
                    <h3 className={styles.title}>{title}</h3>
                    <div className={styles.about}>{text}</div>
                </div> : element}
                {lastChild}
            </div>
        </Overlay>
    )
}

export default AboutOverlay

import { sendForgotPassword } from 'api/authApi'
import BackButton from 'components/BackButton'
import React, { useState } from 'react'
import { alert } from 'react-custom-alert'
import styles from './LoginPage.module.scss'

function ForgotPassword({ userState }) {
    const [email, setEmail] = useState('')
    const [sent, setSent] = useState(false)

    const send = async () => {
        await sendForgotPassword(email)
        setSent(true)
    }

    return (
      <>
          <BackButton className='mb-1'/>
          <h2 className='mb-1'>Återställ</h2>
          <p>Ett email kommer skickas till din adress</p>
            <label className={styles.label}>Email</label>
          <input className={styles.input + ' mb-1'} type='email' value={email} onChange={evt => setEmail(evt.target.value)} />
          <button onClick={send}>Skicka</button>
            { sent && <p>Mail Skickat!</p>}
      </>
  )
}

export default ForgotPassword
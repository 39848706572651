import React from 'react'
import styles from './Spinner.module.scss'

function Spinner({ className = ''}) {
  return (
    <svg viewBox='0 0 50 50' className={styles.Spinner + ' ' + className}>
      <circle className={styles.path} cx='25' cy='25' r='20' fill='none' strokeWidth='5'></circle>
    </svg>
  )
}

export default Spinner
import React, { useState } from 'react'
import { AiFillEyeInvisible, AiFillEye} from 'react-icons/ai'

const icon = {
    verticalAlign: 'middle',
    marginLeft: '4px',
    cursor: 'pointer'
}
function PasswordInput({onChange, value, name, onKeyDown, ...props}) {
    const [visible, setVisible] = useState(false)

    return (
        <span className='flex' style={{ alignItems: 'center'}} {...props}>
            <input type={visible ? 'text' : 'password'} value={value} onChange={onChange} onKeyDown={onKeyDown} name={name}/> 
            <span style={icon} onClick={() => setVisible(prev => !prev)}>{visible ? 
                <AiFillEye size={20}/> : <AiFillEyeInvisible size={20}/>
            }</span>
        </span>
    )
}

export default PasswordInput
import astrologyPic from 'img/libraryImg/astrology.webp'
import medialitetPic from 'img/libraryImg/medialitet.webp'
import chakranPic from 'img/libraryImg/chakran.webp'
import auranPic from 'img/libraryImg/auran.webp'
import twinPic from 'img/libraryImg/twin.webp'
import tarotPic from 'img/libraryImg/tarot.webp'
import spiritPic from 'img/libraryImg/spirit.webp'
import meditationPic from 'img/libraryImg/meditation.webp'
import numberologyPic from 'img/libraryImg/numbers.webp'

export default [
  { name: 'Astrologi', path: 'astrologi', img: astrologyPic },
  { name: 'Medialitet', path: 'medialitet', img: medialitetPic },
  { name: 'Chakran', path: 'chakran', img: chakranPic },
  { name: 'Auran', path: 'auran', img: auranPic },
  { name: 'Tvillingsjälar', path: 'tvilling', img: twinPic },
  { name: 'Tarot', path: 'tarot', img: tarotPic },
  { name: 'Spöken och Andar', path: 'andar', img: spiritPic },
  { name: 'Meditation', path: 'meditation', img: meditationPic },
  { name: 'Nummerologi', path: 'nummerologi', img: numberologyPic },
]
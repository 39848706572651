import React from 'react';

function CompanyLogoV2(props) {
  return <svg {...props}
        width="100%"
         viewBox="0 0 1000 200"
        preserveAspectRatio="xMinYMid meet"
        style={{ backgroundColor: "", fontFamily: 'Fancy', textAlign: 'center', textAnchor: 'middle'}}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <text 
            className={props.textClass}
            style={{ margin: 'auto'}}
            x="50%" 
            y="150"
            fontSize="155"
            fill="white">{'\uE06F'}JU{'\uE06B'}POR<tspan kerning="-12" letterSpacing="10">TA{'\uE06F'}EN</tspan></text>

    </svg>;
}

export default CompanyLogoV2;

import React from 'react'
import styles from './Button.module.scss'

function Button({ children, className, ...props }) {
  return (
    <button className={styles.ctaButton + " " + className} {...props}>
      {children} <span style={{ marginLeft: '8px'}}> &gt;</span>
    </button>
  )
}

export default Button
import { API, authFetch, authFetchPostJson, fetchOrEmptyArray, nonAuthFetch } from "./api"

export const fetchCounselors = () => {
  return fetchOrEmptyArray('/counselor')
}
export const fetchOneCounselor = async (id) => {
  const res = await fetch(`${API}/counselor/${id}`)
  if (!res?.ok) return false
  const json = await res.json()
  return json
}
export const updateCounselor = async (id, counselorInfo, userState) => {
  const data = { ...counselorInfo, isCounselor: true }
  console.log(counselorInfo);
  const res = await authFetchPostJson(`/user/${id}`, data, { method: 'PATCH' }, userState)
  if (!res.ok) return false
  const json = await res.json()
  console.log('res', json);
  return json
}
export const createCounselor = async (counselorInfo, userState) => {
  console.log('hereye', counselorInfo);
  const userInfo = { ...counselorInfo, isCounselor: true }
  const res = await authFetchPostJson('/user', userInfo, {}, userState).then(r => r.json())
  return typeof res === 'object' && res.id
}
export const deleteCounselor = async (id, userState) => {
  try {
    const res = await authFetch(`/user/${id}`, {
      method: 'DELETE'
    }, userState)
    return res.ok
  }
  catch (e) { console.log(e) }
}
export const fetchHoursWorked = async (counselorId, startDate, endDate, userState) => {
  const res = await authFetch(`/counselor/${counselorId}/billableHours?from=${startDate}&to=${endDate}`, {}, userState)
  return await res.json()
}
export const fetchAudioFile = async (resourceId) => {
  const res = await nonAuthFetch(`/resource/${resourceId}`)
  return res.json()
}
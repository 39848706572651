import Button from 'components/Button'
import Img from 'components/Img'
import UserContext from 'context/UserContext'
import personPlaceholder from 'img/personPlaceholder.png'
import { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styles from './CounselorPreview2.module.scss'

function CounselorPreview2({ counselor, buttonText, className = '', name, onClick, readMore }) {
  const userState = useContext(UserContext)
  const [user, setUser] = userState
  const navigate = useNavigate()
  if (!counselor) return null

  const buttonClick = (counselorId) => {
    if (onClick) {
      onClick()
    }
    else {
      navigate(`/se-radgivare/${counselorId}`)
    }
  }

  return (
    <div className={styles.CounselorPreview + ' ' + className}>
      <Img className={styles.image} src={counselor.image?.url} placeholder={personPlaceholder} alt='profilePic' />
      <div className={styles.content}>
        <div>
          {<h4 style={{ marginBottom: '1rem'}}>{ name ? `${counselor.firstName} ${counselor.lastName || ''}` : ''}</h4>}
          <p className='mt-0'>{counselor.shortDescription}</p>
        </div>
        <div className='flex itemsCenter'>

          <Button onClick={() => buttonClick(counselor.id)} className={styles.button + ' ghostBtn'}>
            {buttonText || 'Till ' + counselor.firstName}
          </Button>
          { readMore && <Link to={`/se-radgivare/${counselor.id}`} className='ml-2 clickable'>Läs mer</Link>}
        </div>
      </div>
    </div>
  )
}

export default CounselorPreview2

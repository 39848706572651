import { useRef, useEffect, useCallback } from 'react'

const setFollowerPos = (cursorFollow, x, y) => {
    cursorFollow.current.style.left = x.toString() + 'px'
    cursorFollow.current.style.top = y.toString() + 'px'
}
const getFollowerPos = (cursorFollow) => {
    return {
        x: parseFloat(cursorFollow.current.style.left.replace('px', '')),
        y: parseFloat(cursorFollow.current.style.top.replace('px', ''))
    }
}

let xT, yT = 0 //target positions. Only works placed here unfortunately
export default function useMouseFollow(cursorFollow, lastFollowerPos) {

    const mouseLoopId = useRef()
    const mouseMoveHandle = (e) => {
        xT = e.pageX //Movemove event sets target position 
        yT = e.pageY
    }
    const mouseLoop = useCallback(() => {
        if(!cursorFollow.current) return
        let {x, y} = getFollowerPos(cursorFollow)
        if(!xT) { //When mouse exits UpperPage, for preventing a jump to 0,0
            xT = x
            yT = y
        }
        const newX = x + (xT - x)/20
        const newY = y + (yT - y)/20
        setFollowerPos(cursorFollow, newX, newY)
        
        lastFollowerPos.x = newX //Save  last pos
        lastFollowerPos.y = newY
        window.requestAnimationFrame(mouseLoop)
    }, [cursorFollow, lastFollowerPos])
    
    useEffect(() => {//When Switching page, we dont want mouse reset to 0,0, if a saved position exists, use it
        setFollowerPos(cursorFollow, lastFollowerPos.x || 0, lastFollowerPos.y || 0) 
        mouseLoopId.current = window.requestAnimationFrame(mouseLoop)
        
        return () => {
            window.cancelAnimationFrame(mouseLoopId.current)//Unmoiunt, destroy loop
        }
    }, [cursorFollow, lastFollowerPos.x, lastFollowerPos.y, mouseLoop])
    return mouseMoveHandle
}
import { fetchCounselors } from 'api/counselorApi'
import About from 'components/About'
import CounselorPreview2 from 'components/CounselorPreview2'
import CtaBox from 'components/CtaBox'
import Footer from 'components/Footer'
import Header from 'components/Header'
import TitleUnderline from 'components/TitleUnderline'
import UpperPage from 'components/UpperPage'
import CompanyLogo from 'img/CompanyLogo'
import homeImg from 'img/startsida.webp'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BlogSubpage from './BlogSubpage'
import CounselorSubpage from './CounselorSubpage'
import styles from './Home.module.scss'
import LibrarySubpage from './LibrarySubpage'

function Home() {
  const navigate = useNavigate()

  return (
    <div className={styles.Home} >
      <UpperPage
        bgImgUrl={homeImg}
        whiteText
        title={
          <div className={styles.mainTitle}>
            <CompanyLogo />
            <p className={styles.smaller}>Din mediala vägledare</p>
          </div>
        }
      >
        <CtaBox
          className={styles.frontCtaBox}
          title='Kostnadsfri konsultation'
          text='Vill du kontakta ditt medium innan bokning?'
          btnText='Välj rådgivare'
          onClick={() => navigate(`/se-radgivare/kontakta`)}
          />
        <CtaBox
          className={styles.frontCtaBox}
          title='Boka samtal'
          text='Vi erbjuder rådgivning över video och telefon'
          btnText='Boka nu'
          onClick={() => navigate(`/se-radgivare`)}
        />

      </UpperPage>
      <CounselorSubpage />
      <BlogSubpage />

      <LibrarySubpage />

      <Footer />

    </div>)
}

export default Home

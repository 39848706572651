import Button from 'components/Button'
import React from 'react'
import styles from './CtaBox.module.scss'

function CtaBox({ title, text, btnText, className, children, onClick }) {
  return (
    <div className={styles.ctaBox + ' ' + className}>
      <div >
        <h2 className={styles.title}>{title}</h2>
        { text && <p className={styles.text}>{ text }</p> }
        {children}
      </div>
      <Button className={styles.button} onClick={onClick}>{btnText}</Button>       
  </div>
  )
}

export default CtaBox
import { fetchCounselors } from 'api/counselorApi'
import CounselorPreview2 from 'components/CounselorPreview2'
import CtaBox from 'components/CtaBox'
import TitleUnderline from 'components/TitleUnderline'
import { segmentPrice } from 'config'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { pickRandomInRange } from 'util/utils'
import styles from './Home.module.scss'

function CounselorSubpage() {
  const [counselors, setCounselors] = useState([])
  const navigate = useNavigate()

  const setUp = async () => {
    const counselors = await fetchCounselors()
    if(!counselors.length) return
    const picks = pickRandomInRange(3, counselors.length)
    setCounselors(picks.map(i => counselors[i]))
  }
  useEffect(() => {
    setUp()
  }, [])

  return (
    <div className={styles.subPage} >
        <div className={styles.counselorSpread}>
          <div className={styles.counselorListContainer}>
            <h3  style={{ width: 'max-content'}}><span className='borderBottom'>Våra rådgivare</span> <Link to='/se-radgivare' style={{ fontSize: '0.7em'}} className='ml-1 underline'>Se alla</Link></h3>
            <div className={styles.counselorList}>
              {counselors.map(counselor =>
                <CounselorPreview2 key={counselor.id} counselor={counselor} />
              )}
            </div>
          </div>

          <div className={styles.ctaBoxes}>
            <CtaBox title='Kostnadsfri konsultation' btnText='Boka' onClick={() => navigate('/se-radgivare/kontakta#scrollTarget')} titleSize={26} text='Vill du kontakta ditt medium innan bokning?' />
            <CtaBox title='Samtal via video och telefon' titleSize={26} btnText='Till bokning' onClick={() => navigate('/boka')}>
              <div>
                <p className='mb-0'><span>20min:</span> <span className='lowerPrice'>{segmentPrice}kr</span> <span className='overline'>500</span>kr</p>
                <p className='mb-0'><span>40min:</span> <span className='lowerPrice'>{segmentPrice*2}kr</span> <span className='overline'>1000</span>kr</p>
                <p className='mb-1'><span>60min:</span> <span className='lowerPrice'>{segmentPrice*3}kr</span> <span className='overline'>1500</span>kr</p>
              </div>
            </CtaBox>
          </div>
      </div>
    </div>
  )
}

export default CounselorSubpage
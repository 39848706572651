import React from 'react'
import NavBar from '../NavBar'
import styles from './Header.module.scss'
import { NavLink } from 'react-router-dom'

function Header({opaqueMode, midTitle, whiteText, visibilityHelp, id}) {
    let classNames = [styles.Header]
    whiteText && classNames.push(styles.whiteText)
    opaqueMode && classNames.push(styles.opaqueMode)
    visibilityHelp && classNames.push(styles.gradient)
    
    return (
        <div className={classNames.join(' ')} id={id}>
            {
                midTitle && <NavLink to='/' className={styles.midTitle}><h2>Ljusportalen</h2></NavLink>
            }
            <NavBar />
        </div>
    )
}

export default Header

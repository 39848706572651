import './App.css';
import './css/utility.scss'
import { useEffect, useState } from 'react';
import MusicControl from 'components/MusicControl'
import WelcomeOverlay from 'components/WelcomeOverlay'
import AppRoutes from './routes/AppRoutes.js'
import MouseContext from 'context/MouseContext';
import UserContext from 'context/UserContext';
import { getAccessToken } from 'api/authApi';
import CustomAlert from 'components/CustomAlert';
import jwt_decode from 'jwt-decode';
import { alert } from 'react-custom-alert';
import { SystemZone, Settings } from 'luxon'
import { useLocation, useSearchParams } from 'react-router-dom';
import { QueryClient, QueryClientProvider, ReactQueryDevtool } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import UnderConstructionPage from 'pages/UnderConstructionPage';
const queryClient = new QueryClient()
Settings.defaultLocale = 'sv-SE'

function App() {
  const [hasClicked, setHasClicked] = useState(localStorage.getItem('hasVisited'))
  const [lastMousePos] = useState({ x: 0, y: 0 })
  const userState = useState({ refreshToken: localStorage.getItem('refreshToken') })
  const [user, setUser] = userState
  const [secretClicked, setSecretClicked] = useState(false)
  const [searchParams] = useSearchParams() //For attaching guest token to user object on guestpages. Then they can make auth requests
  const location = useLocation()

  useEffect(() => { //When App mounts, get either accesstoken or param token for auth
    if (searchParams.has('token')) {
      setUser(prev => ({ ...prev, guestToken: searchParams.get('token') }))
      return
    }
    if (user.accessToken || !user.refreshToken) {
      return
    }
    //Refreshtoken exists, get an accesstoken so auth requests can be made in the app
    getAccessToken(user.refreshToken).then(accessToken => {
      const decodedAccessToken = jwt_decode(accessToken)
      setUser(prev => ({ ...prev, accessToken, ...decodedAccessToken }))
    }).catch((err) => {
      alert({ type: 'error', message: 'Din session har utgått' })
      localStorage.removeItem('refreshToken')
      console.log(err);
    })
  }, [])

  useEffect(() => {

    //I dont rely on this one anymore, but dont remove because of fear of breaking
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1))
      console.log('hash', location.hash.slice(1), elem);
      if (elem) {
        elem.scrollIntoView(true)
      }
    } else {
      // window.scrollTo({top:0,left:0, behavior: "smooth"})
    }
  }, [location,])


  const overlayClick = () => {
    setHasClicked(true)
    localStorage.setItem('hasVisited', true) //If has visited then I dont wanna display annyoing welcome screen everytime
  }
  if(!secretClicked) return <UnderConstructionPage onSecret={() => setSecretClicked(true)} />
  
  return (
    <div className="App">
      <WelcomeOverlay in={false && !hasClicked} onClick={overlayClick} />
      <MouseContext.Provider value={lastMousePos}>
        <UserContext.Provider value={userState}>
          <QueryClientProvider client={queryClient}>
            <AppRoutes />
            <CustomAlert />
            <ReactQueryDevtools />
          </QueryClientProvider>
        </UserContext.Provider>
      </MouseContext.Provider>
      <MusicControl />
    </div>
  );
}

export default App;

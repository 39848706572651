import React from 'react'
import styles from './CounselorListItem.module.scss'
import personPlaceholder from 'img/personPlaceholder.png'
import Img from 'components/Img'

function CounselorListItem({ counselor, className = '', simple, hoverText, id }) {
  
  if(!counselor) return null

  return (
    <div className={`${styles.CounselorListItem} ${className}`} id={id}>
      <h4 className={styles.name}>{`${counselor.firstName || ''} ${counselor.lastName || ''}`}</h4>
      <div className={`${styles.horizontalBar} mb-1`}></div>
      <div className={`${styles.imageContainer} ${simple ? '' : styles.decorate}`}>
        <Img src={counselor.image?.url} placeholder={personPlaceholder} className={styles.counselorImg} alt='' />
      </div>
      {hoverText && <div className={styles.textOverlay}>
        {counselor.shortDescription}
      </div>}
    </div>
  )
}

export default CounselorListItem
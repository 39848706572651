import React, { useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import styles from './Overlay.module.scss'

function Overlay({ children, onOutsideClick, in: inProp, overflow }) {
    const overlay = useRef()
    
    useEffect(() => {
      document.body.classList.toggle('noScroll', inProp)
      return () => {
        document.body.classList.remove('noScroll')
      }
    }, [inProp])
    
    const onOverlayClick = (e) => {
        e.stopPropagation();
        e.target.contains(overlay.current) && onOutsideClick()
    }
    return (
        <CSSTransition
            nodeRef={overlay}
            in={inProp}
            classNames={{
                enter: styles.enter,
                enterActive: styles.enterActive,
                exit: styles.exit,
                exitActive: styles.exitActive,
            }}
            appear
            unmountOnExit
            timeout={{enter: 500, exit: 500}}
        >
            <div className={`${styles.Overlay} ${overflow ? styles.overflow : ''} clickable`}  onClick={onOverlayClick} ref={overlay}>
                {children}
            </div>
        </CSSTransition>
        
    )
}

export default Overlay

import { authFetch, authFetchOrEmptyArray, authFetchPostJson, fetchOrEmptyArray, nonAuthFetch } from "./api"

export const fetchBlogPosts = (page = 1, authorId) => {
  let path = `/blog-post?take=100&page=${page}`
  if (authorId) {
    path += `&authorId=${authorId}`
  }
  return fetchOrEmptyArray(path, 'data')
}
export const editBlogPost = async (postId, post, userState) => {
  const res = await authFetchPostJson(`/blog-post/${postId}`, {
    content: post.content, title: post.title, isDraft: post.isDraft
  }, {
    method: 'PATCH'
  }, userState)
  return res.ok
}
export const deleteBlogPost = async (postId, userState) => {
  const res = await authFetch(`/blog-post/${postId}`, {
    method: 'DELETE'
  }, userState)
  return res.ok
}
export const fetchBlogOneCounselor = (counselorId) => {
  return fetchOrEmptyArray(`/blog-post?authorId=${counselorId}&take=100&page=1`, 'data')
}
export const fetchMyBlog = (userState) => {
  return authFetchOrEmptyArray(`/blog-post?take=100&page=1&draftStatus=all&authorId=${userState[0].id}`, userState, 'data')
}
export const fetchOneBlogPost = async (postId) => {
  const res = await nonAuthFetch(`/blog-post/${postId}`)
  const json = await res.json()
  return json
}
export const postBlog = async (post, userState) => {
  const res = await authFetchPostJson('/blog-post', {
    title: post.title,
    content: post.content,
    authorId: userState[0].id,
    isDraft: post.isDraft,
  }, {}, userState)
  if (!res.ok) return false

  return res.json()
}
export const subscribeToAuthor = async (authorId, userState) => {
  const res = await authFetchPostJson('/author/subscribe', {
    authorId
  }, {}, userState)
  return res.ok
}
export const unsubscribeToAuthor = async (authorId, userState) => {
  const res = await authFetchPostJson('/author/unsubscribe', {
    authorId
  }, {}, userState)
  return res.ok
}
export const fetchMySubscriptions = async (userState) => {
  const res = await authFetch('/me/subscriptions', {}, userState)  
  if(!res.ok) throw 'Not 200'
  
  const subscriptions = await res.json()
  if(!Array.isArray(subscriptions)) throw 'Not array response'

  return subscriptions
}
import DOMPurify from 'dompurify'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import styles from './BlogPost.module.scss'

function BlogPost({ post, author, onShow, short, className = '' }) {
  if (!post) return null
  const innerHTML = DOMPurify.sanitize(post.content)

  let publishedDate = ''
  if (post.publishedDate) {
    const pubDate = DateTime.fromISO(post.publishedDate)
    publishedDate = post.publishedDate ? `${pubDate.toFormat('HH:mm')} ${pubDate.day} ${pubDate.monthShort} ${pubDate.year} ` : ''
  }
  return (
    <div className={`${styles.BlogPost}  ${className}`} style={{ maxHeight: short ? '360px' : '', maxWidth: '600px' }}>
      <div>
        <div className='flex itemsCenter mb-1'>
          <h4 className={styles.title}>{post.title}</h4>
          {post.isDraft && <small className='ml-1'>Utkast</small>}
        </div>

        <div className={styles.content} style={{ maxHeight: short ? '230px' : ''}} dangerouslySetInnerHTML={{ __html: short ? innerHTML.substring(0, 230) : innerHTML }} />
      </div>

      <p className={styles.bottomBar}>
        {short && <span onClick={onShow} className='clickable underline mr-1'>Visa</span>}
        <span><b>{`${author?.firstName || ''} ${author?.lastName || ''}`}</b> {publishedDate}</span>
      </p>
    </div>
  )
}

export default BlogPost

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MdArrowBack } from 'react-icons/md'
import styles from './BackButton.module.scss'

function BackButton({ className = '', path, style={}, scroll}) {
    const navigate = useNavigate()
    const to = path ? `${path}${scroll ? '#scrollTarget' : ''}` : -1
    return (
        <div onClick={() => navigate(to)} className={`clickable ${styles.button} ${className}`}>
        <MdArrowBack  className={styles.icon}  style={style}/>
            <span>Tillbaka</span>
        </div>
    )
}

export default BackButton
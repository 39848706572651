import React from 'react'

function TitleUnderline({ text, className = '', id }) {
  return (<div className={'lowerPageTitle'+ className} id={id} style={{ marginLeft: '0.5rem'}}>
        <h3>{text}</h3>
        <span className='bar'></span>
  </div>
  )
}

export default TitleUnderline
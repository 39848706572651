import songs from 'audio/merged.mp3'
import useAudio from 'hooks/useAudio'
import { BsMusicNote } from 'react-icons/bs'
import styles from './MusicControl.module.scss'
const MAX_VOL = 0.5

const song = new Audio(songs)
song.currentTime = Math.floor(Math.random() * 100)
song.volume = MAX_VOL

function MusicControl() {
  const [playing, toggle] = useAudio(song)

  return (
    <div
      className={`${styles.MusicControl} ${playing ? styles.playing : ''}`}
      onClick={() => toggle()}
    >

      <BsMusicNote
        className={styles.icon}
      />
    </div>
  )
}

export default MusicControl

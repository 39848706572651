import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import Home from 'pages/Home';
import Spinner from 'components/Spinner';
import LoginPage from 'pages/LoginPage'

const AdminRoutes = React.lazy(() => import('./AdminRoutes'))
const CounselorRoutes = React.lazy(() => import('routes/CounselorRoutes'))
const BlogPage = React.lazy(() => import('pages/BlogPage'))
const BookingPage = React.lazy(() => import('pages/BookingPage'))
const CounselorsPage = React.lazy(() => import('pages/CounselorsPage'))
const GuestCancelBookingPage = React.lazy(() => import('pages/GuestCancelBookingPage'))
const LibraryPage = React.lazy(() => import('pages/LibraryPage'))
const ManageAuthPage = React.lazy(() => import('pages/ManageAuthPage'))
const MyPage = React.lazy(() => import('pages/MyPage'))
const NotFoundPage = React.lazy(() => import('pages/NotFoundPage'))
const PayPage = React.lazy(() => import('pages/PayPage'))
const PolicyPage = React.lazy(() => import('pages/PolicyPage'))
const VideoClientPage = React.lazy(() => import('pages/VideoClientPage'))

function AppRoutes() {
  //Dont want to display 404 page in breif flash when access token is fetching
  // if(user.refreshToken && !user.accessToken) 
  //   return null
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route index element={<Home />} />
        <Route path='villkor/*' element={<PolicyPage />} />
        <Route path='losenord/*' element={<ManageAuthPage />} />
        <Route path='se-radgivare/*' element={<CounselorsPage />} />
        <Route path='gast-avboka/:bookingId' element={<GuestCancelBookingPage />} />
        <Route path='blogg/*' element={<BlogPage />} />
        <Route path='bibliotek/*' element={<LibraryPage />} />
        <Route path='boka' element={<BookingPage />} />
        <Route path='login/*' element={<LoginPage />} />
        <Route path='betala/*' element={<PayPage />} />
        <Route path='video/:bookingId' element={<VideoClientPage />} />
        <Route path='video' element={<VideoClientPage />} />
        <Route path='minsida/*' element={<AuthRoute element={<MyPage />} />} />
        <Route path='admin/*' element={<AuthRoute admin element={<AdminRoutes />} />} />
        <Route path='radgivare/*' element={<AuthRoute counselor element={<CounselorRoutes />} />} />

        {
          // (user.isAdmin || user.isCounselor) ? 
          // <Route path='minsida/*' element={} />
          // :
          // <Route path='minsida/*' element={<ProfilePage />} />
        }
        <Route path='*' element={<NotFoundPage />} />

      </Routes>
    </Suspense>
  )
}

export default AppRoutes

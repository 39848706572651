import React, { useState } from 'react'
import AboutOverlay from '../AboutOverlay';

function PopupTriggerText({ popupContent, children, element }) {
    const [focus, setFocus] = useState(false)

    return (
        <span className='clickable' onClick={() => setFocus(true)}>
            {children}
            <AboutOverlay
                in={focus}
                title={popupContent?.title}
                text={popupContent?.text}
                element={element}
                onOutsideClick={() => setFocus(false)}
            />
        </span>
    )
}

export default PopupTriggerText

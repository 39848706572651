import { getAccessToken, submitLogin } from 'api/authApi'
import React, { useContext, useEffect, useState } from 'react'
import { alert } from 'react-custom-alert'
import jwtDecode from 'jwt-decode'
import styles from './LoginPage.module.scss'
import UserContext from 'context/UserContext'
import { useLocation, useNavigate } from 'react-router-dom'
import BackButton from 'components/BackButton'
import PasswordInput from 'components/PasswordInput'
import { useMutation } from 'react-query'

function DoLogin() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [user, setUser] = useContext(UserContext)
  const location = useLocation()
  const navigate = useNavigate()
  const { isLoading, mutate: sendLogin } = useMutation('login', async () => {
    const refreshToken = await submitLogin({ email, password })
    if (!refreshToken) {
      alert({ type: 'error', message: 'Kunde inte logga in' })
      throw 'Failed to login'
    }
    let accessToken = await getAccessToken(refreshToken)
    const decodedAccessToken = jwtDecode(accessToken)
    setUser(prev => ({ ...prev, accessToken, ...decodedAccessToken }))
    navigate(from, { replace: true })
    return true
  })

  let from = location.state?.from?.pathname || "/"

  useEffect(() => { //Waiting on loginpage until login either by user or with saved tokens
    if (user.accessToken) {
      navigate(from, { replace: true, state: { savedBooking: location.state?.savedBooking } })
    }
  }, [user])


  const handlePasswordKey = async (evt) => {
    if (evt.key == 'Enter') {
      sendLogin()
    }
  }

  return (
    <>
      <BackButton path='/login' className='mb-1 invisible' />

      <h2 className='mb-2 leftAlign'>Logga in</h2>

      <label className={styles.label}>Email</label>
      <input className={styles.input + ' mb-1'} type='email' value={email} onChange={evt => setEmail(evt.target.value)} />

      <label className={styles.label}>Lösenord</label>
      <PasswordInput value={password} onKeyDown={handlePasswordKey} onChange={evt => setPassword(evt.target.value)} />

      <span onClick={() => navigate('/login/glomt')} style={{ marginBottom: '4px' }} className='underline clickable leftAlign'>Glömt lösenord?</span>
      <span onClick={() => navigate('/login/skapa')} className='underline mb-1 clickable leftAlign'>Skapa konto</span>
      <button onClick={sendLogin}>{isLoading ? 'Loggar in...' : 'Logga in'}</button>
    </>
  )
}

export default DoLogin
import { useEffect, useState } from "react";

const useAudio = audioObj => {
  const [audio, setAudio] = useState(audioObj ? audioObj : null);
  const [playing, setPlaying] = useState(false);

  const toggle = (forcePause) => {
    const newIsPlaying = forcePause ? false : !playing
    setPlaying(newIsPlaying)
    newIsPlaying ? audio.play() : audio.pause();
  };

  useEffect(() => {
    if (!audio) return
    playing ? audio.play() : audio.pause();
  },
    [playing]
  );

  useEffect(() => {
    audio && audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio && audio.pause()
      audio && audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, [audio]);

  return [playing, toggle, setAudio];
}

export default useAudio
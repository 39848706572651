import jwt_decode from "jwt-decode"
import { authFetchPostJson } from "./api";
const API = process.env.REACT_APP_API_URL + '/v1/api'

export const clearTokens = async (setuser) => {
    localStorage.removeItem('refreshToken')
    setuser(prev => ({...prev, accessToken: undefined, refreshToken: undefined}))
}
export const createAccount = async ({ email, password, firstName, lastName }) => {
    const res = await fetch(API + '/user', {
        method: 'POST', headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ email, password, firstName, lastName })
    })
    return res.ok
}
export const sendForgotPassword = async (email) => {
    const res = await fetch(API+'/auth/sendPasswordResetEmail', {
        method: 'POST', headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ email })
    })
    return res.ok
}
export const sendVerifyEmail = async (id, userState) => {
    const res = await authFetchPostJson('/auth/sendVerificationEmail', {id}, {}, userState)
    return res.ok
}
export const postVerifyEmailToken = async (token) => {
    const res = await fetch(`${API}/auth/verifyEmail`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ token })
    })
    return res.ok
}
export async function submitLogin(userData) {
    const res = await fetch(`${API}/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData)
    })
    if(!res.ok) return false
    const json = await res.json()
    localStorage.setItem('refreshToken', json.refreshToken)
    console.log('RT', jwt_decode(json.refreshToken));
    return json.refreshToken
}

export async function getAccessToken(refreshToken) {
    const res = await fetch(`${API}/auth/refresh`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refreshToken })
    })
    if(!res.ok) throw 'Getting accessToken not 200 response'
    
    const json = await res.json()
    return await json.accessToken
};
export function logout(setUser) {
    localStorage.removeItem('refreshToken')
    setUser({})
}
import Header from 'components/Header';
import UserContext from 'context/UserContext.js';
import React, { useContext } from 'react';
import { Route, Routes,  } from 'react-router-dom';
import AccountWasCreated from './AccountWasCreated';
import CreateAccount from './CreateAccount';
import DoLogin from './DoLogin';
import ForgotPassword from './ForgotPassword';
import styles from './LoginPage.module.scss';

function LoginPage() {
    const userState = useContext(UserContext) 
    
    return <div>
        <Header opaqueMode midTitle/>
        <div className={'panelPage ' + styles.LoginPage}>
            <div className={styles.loginForm}>
                <Routes>
                    <Route index element={<DoLogin userState={userState} />} />
                    <Route path='glomt' element={<ForgotPassword userState={userState} />} />
                    <Route path='skapa' element={<CreateAccount  />} />
                    <Route path='konto-skapat' element={<AccountWasCreated />} />
                </Routes>
                
            </div>
        </div>
    </div>
}

export default LoginPage;

import React, {useContext, useState} from 'react'
import { NavLink } from 'react-router-dom'
import styles from './NavBar.module.scss'
import UserContext from 'context/UserContext.js'
import jwt_decode from 'jwt-decode'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'

const getLinkClass = ({ isActive }) => {  //React Router V6 removed activeClassName prop :(
    return isActive ? styles.link + ' ' + styles.activeLink : styles.link
}
const Links = ({ userData }) => <>
    <NavLink className={getLinkClass} id='linkToBook' to='/boka'> Boka </NavLink>
    <NavLink className={getLinkClass} id='linkToAdvisor' to='/se-radgivare'> Rådgivare </NavLink>
    <NavLink className={getLinkClass} id='linkToLibrary' to='/bibliotek'> Bibliotek </NavLink>
    <NavLink className={getLinkClass} id='linkToBlog' to='/blogg'> Blogg </NavLink>
    {userData ? 
        <NavLink className={getLinkClass} id='linkToBlog' to='/minsida'>Min Sida</NavLink>
        :
        <NavLink className={getLinkClass} id='linkToBlog' to='/login'> Min Sida </NavLink>
    }
</>

function NavBar({ className = '' }) {
    const [user, setuser] = useContext(UserContext)
    const [expanded, setExpanded] = useState(false)
    const userData = user.accessToken ? jwt_decode(user.accessToken) : null

    return ( 
        <div className={`${styles.NavBar} ${className}`}>
            <div className={styles.fullMenu}>
                <Links userData={userData} />
            </div>

            <AiOutlineMenu size={30} className={'clickable ' + styles.toggleBtn} onClick={() => setExpanded(true)}/>
            
            {expanded && <div className={styles.hamburgerMenu}>
                <AiOutlineClose className={styles.closeHamburger + ' clickable'} color='black' size={25} onClick={() => setExpanded(false)} />
                <Links userData={userData} />
            </div>}
        </div>
    )
}

export default NavBar

import { createAccount } from 'api/authApi'
import BackButton from 'components/BackButton'
import React, { useState } from 'react'
import styles from './LoginPage.module.scss'
import { alert } from 'react-custom-alert' 
import { useNavigate } from 'react-router-dom'

function CreateAccount() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const navigate = useNavigate()

    const create = async () => {
        if(!email || !password || !firstName) {
            alert({ type: 'error', message: 'Vänligen fyll i förnamn, email och lösenord'})
            return
        }
        const success = await createAccount({ email, password, firstName, lastName })
        if(success) {
            alert({ type: 'success', message: 'Konto skapat!'})
            navigate('/login/konto-skapat')
        } else {
            alert({ type: 'error', message: 'Konto kunde ej skapas'})
        }
    }
    return (
        <>
            <BackButton className='mb-1' />
            <h2 className='mb-2'>Skapa Konto</h2>
            <label className={styles.label}>Förnamn</label>
            <input className={styles.input + ' mb-1'} type='text' value={firstName} onChange={evt => setFirstName(evt.target.value)} />
            <label className={styles.label}>Efternamn (valfri)</label>
            <input className={styles.input + ' mb-1'} type='text' value={lastName} onChange={evt => setLastName(evt.target.value)} />
            <label className={styles.label}>Email *</label>
            <input className={styles.input + ' mb-1'} type='email' value={email} onChange={evt => setEmail(evt.target.value)} />
            <label className={styles.label}>Lösenord</label>
            <input className={styles.input + ' mb-2'} type='password' value={password} onChange={evt => setPassword(evt.target.value)} />
            <button onClick={create}>Skapa</button>
        </>
    )
}

export default CreateAccount
import UserContext from 'context/UserContext'
import NoPage from 'pages/NoPage'
import React, { useContext, useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

function AuthRoute({ element, admin, counselor, author }) {
    const [user, setUser] = useContext(UserContext)
    const location = useLocation()
    
    if(!user.accessToken) {
        return <Navigate to='/login' state={{ from: location}} replace />
    }
    
    const hasAccess = (props) => {
        const allowAllUsers = !admin && !counselor && !author
        return allowAllUsers || (admin && user.isAdmin ) || (counselor && user.isCounselor) || (author && user.isAuthor)
    }
    
    if(hasAccess()) return element
    
    return <NoPage message='Nekad åtkomst' />
}

export default AuthRoute
import React from 'react'
import { Link } from 'react-router-dom'

function AccountWasCreated() {
  return (
    <>
      <h3 className='mb-2'>Ditt konto har skapats!</h3>
      <p style={{alignSelf: 'center'}}>Du kan nu logga in här: </p>
      <Link to='/login' style={{alignSelf: 'center'}}><button>Logga in</button></Link>
    </>
  )
}

export default AccountWasCreated
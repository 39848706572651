import Footer from 'components/Footer'
import Header from 'components/Header'
import React from 'react'

function NoPage({ message }) {
  return (
    <div>
      <Header opaqueMode midTitle/>
        <div className='lowerPage' style={{ minHeight: 500}}>

        <h1>{message}</h1>
        </div>
        <Footer />
    </div>
  )
}

export default NoPage
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Topic.module.scss'

function Topic({ topic }) {

  return (<Link to={`/bibliotek/${topic.path}`}>
    <div className={styles.Topic + ' clickable'}>
      <img className={styles.smallPic} src={topic.img} alt='Topic Pic' />
      <h3 className={styles.name}>{topic.name}</h3>
    </div>
  </Link>
  )
}

export default Topic

import Button from 'components/Button'
import TitleUnderline from 'components/TitleUnderline'
import Topic from 'components/Topic'
import AllTopics from 'db/AllTopics'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { pickRandomInRange } from 'util/utils'
import styles from './Home.module.scss'


function LibrarySubpage() {
  const [topics, setTopics] = useState([])
  const navigate = useNavigate()

  const setUp = async () => {
    const picks = pickRandomInRange(3, AllTopics.length)
    setTopics(picks.map(n => AllTopics[n]) )
  }
  useEffect(() => {
    setUp()
  }, [])

  return (
    <div className={styles.subPage + ' mb-2'} >
      <TitleUnderline text='Bibliotek' className='mb-2'/>
      <div className={styles.libraryList}>
        {
          topics.map(topic => <Topic key={topic.id} topic={topic} />)
        }
      </div>
      <Button onClick={() => navigate(`/bibliotek#scrollTarget`)} className={styles.libraryButton}>Till bibliotek</Button>
    </div>
  )
}

export default LibrarySubpage
import { clearTokens, getAccessToken } from "./authApi"
import jwt_decode from 'jwt-decode';
import { alert } from "react-custom-alert";

export const API = process.env.REACT_APP_API_URL + '/v1/api'
export const fetchOrEmptyArray = async (url, field) => {
  try {
    const response = await fetch(`${API}${url}`)
    if (!response.ok) throw new Error("Response not 200")
    const json = await response.json()

    if (!field) return json
    if (Array.isArray(json[field])) return json[field]
    throw new Error(`Field '${field}' was not an array`)
  } catch (e) {
    console.log(e);
    return []
  }
}
export const fetchOneAuthor = async (id) => {
  const res = await fetch(`${API}/author/${id}`)
  if (!res?.ok) return false
  const json = await res.json()
  return json
}
export const fetchAuthors = () => {
  return fetchOrEmptyArray('/author')
}
export const fetchAvailableTimes = (counselorId, beginDate, endDate) => {
  if (!beginDate || !endDate) throw 'Dates are needed'
  const beginStr = beginDate.toISO().substr(0, 10)
  const endStr = endDate.toISO().substr(0, 10)
  return fetchOrEmptyArray(`/available-time-segment?counselorId=${counselorId}&begin=${beginStr}&end=${endStr}`)
}
export const postAvailableTimes = async (counselorId, begins, lengthMinutes, userState) => {
  console.log('posting these', begins, lengthMinutes);
  const res = await authFetchPostJson('/available-time-rule', {
    counselorId, begins: begins.toISO(), lengthMinutes,
    recurring: {
      every: 1,
      repeatTimes: 1,
      except: [
      ]
    }
  }, {}, userState)
  if (!res.ok) return false

  return res.json()
}
export const deleteAvailableTimeRule = async (timeRuleId, userState) => {
  const res = await authFetch(`/available-time-rule/${timeRuleId}`, {
    method: 'DELETE'
  }, userState)

  return res.ok
}
export const updateProfile = async (profile, userState) => {
  const [, setUser] = userState
  const res = await authFetch(`/user/${userState[0].id}`, {
    method: 'PATCH', body: JSON.stringify(profile), headers: { 'Content-Type': 'application/json' },
  }, userState)
  const newProfile = await res.json()
  setUser(prev => ({ ...prev, ...newProfile })) //setUser
  return newProfile
}
export const fetchMyself = async (userState) => {
  const res = await authFetch('/me', {}, userState)
  return await res.json()
}

export const fetchCounselorBookings = () => {
  return fetchOrEmptyArray(`/me/booked`)
}
export const rememberDescription = (text) => {
  if (typeof text !== 'string') throw 'Input must be string'
  localStorage.setItem('creatingCounselorDescription', text)
}
export const uploadResource = async (resFile, userState) => {
  const formData = new FormData()
  formData.append('resource', resFile)

  const res = await authFetch('/resource', { method: 'POST', body: formData }, userState)
  const json = await res.json()
  return json
}
export const authFetchPostJson = async (url, body, options, userState) => {
  return authFetch(url, {
    method: 'POST',
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options.headers,
    },
    body: JSON.stringify(body),
  }, userState)
}
export const nonAuthFetch = (url, ...args) => {
  return fetch(`${API}${url}`, ...args)
}
export const authFetch = async (url, options, userState) => {
  if (!userState) throw 'Falsy userState'

  const [user, setUser] = userState
  let AT = user.accessToken
  let decoded = AT ? jwt_decode(AT) : null
  const invalidAccessToken = !AT || new Date(decoded.exp) < Date.now()

  if(user.guestToken) {//For pages with guest stuff, I attach guestToken from searchParams onto user object
    return fetch(`${API}${url}?token=${user.guestToken}`, options)
  } else if (!user.accessToken && !user.refreshToken) { //No refresh OR accesstoken
    console.log('No refresh token! Cannot make request');
    // window.location.replace('/login')
    return
  } else if (user.refreshToken && invalidAccessToken) {
    //If only accesstoken is missing or expired
    try {
      AT = await getAccessToken(user.refreshToken)
    } catch (err) {
      alert({ type: 'error', message: 'Försök logga in' })
      clearTokens(setUser)
      console.error(err);
      return
    }
    setUser(prev => ({ ...prev, accessToken: AT }))
  }

  return fetch(`${API}${url}`, {
    ...options,
    headers: {
      ...options.headers,
      'Authorization': `Bearer ${AT}`
    }
  })
}
export const authFetchOrEmptyArray = async (url, userState, field) => {
  try {
    const response = await authFetch(url, {}, userState)
    if (!response.ok) throw new Error("Response not 200")
    const json = await response.json()

    if (!field) return json
    if (Array.isArray(json[field])) return json[field]
    throw new Error(`Field '${field}' was not an array`)
  } catch (e) {
    console.error(e);
    return []
  }
}
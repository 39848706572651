import React from 'react'

const server = process.env.REACT_APP_API_URL

function Img(props) {
  let src = props.src ? `${server}${props.src}` : props.placeholder

  // src after {...props} important, otherwise src isnt calculated above
  return (
    <img {...props} src={src}/>
  )
}

export default Img
import React, { useEffect, useState } from 'react'
import CounselorPreview2 from 'components/CounselorPreview2'
import CtaBox from 'components/CtaBox'
import TitleUnderline from 'components/TitleUnderline'
import styles from './Home.module.scss'
import Button from 'components/Button'
import { fetchBlogPosts } from 'api/blogApi'
import BlogPost from 'components/BlogPost'
import { useNavigate } from 'react-router-dom'


function BlogSubpage() {
  const [blogPosts, setBlogPosts] = useState([])
  const navigate = useNavigate()

  const setUp = async () => {
    const posts = await fetchBlogPosts()
    setBlogPosts(posts.slice(0, 2))

  }
  useEffect(() => {
    setUp()
  }, [])

  return (
    <div className={styles.subPage}>
      <TitleUnderline text='Blogg' className='mb-1' />
      <div className={styles.blogPostList}>
        {
          blogPosts.map(post => <BlogPost key={post.id} post={post} short className={styles.blogPost} onShow={() => navigate(`/blogg/${post.id}`)} />)
        }
      </div>
      <Button onClick={() => navigate(`/blogg#scrollTarget`)}>Till bloggen</Button>
    </div>
  )
}

export default BlogSubpage